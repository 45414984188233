<template>
    <div>
        工资表管理
    </div>
</template>

<script>
    export default {
        name: "SalTable"
    }
</script>

<style scoped>

</style>