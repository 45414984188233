import { render, staticRenderFns } from "./SalTable.vue?vue&type=template&id=fb56836a&scoped=true&"
import script from "./SalTable.vue?vue&type=script&lang=js&"
export * from "./SalTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb56836a",
  null
  
)

export default component.exports